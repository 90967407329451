import { default as about3iuMTWzDTyMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/about.vue?macro=true";
import { default as accountBESHg8aqL7Meta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/account.vue?macro=true";
import { default as cookie_45policyba5x47R1oWMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/cookie-policy.vue?macro=true";
import { default as explorevFGG1nNMswMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/explore.vue?macro=true";
import { default as faqTvYfKvJm1uMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/faq.vue?macro=true";
import { default as indexYZFNs6ILSoMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/index.vue?macro=true";
import { default as loginayTM77Ne30Meta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/login.vue?macro=true";
import { default as pricingwrWJAfbU2xMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/pricing.vue?macro=true";
import { default as privacy_45policyE4rPhWnZ9EMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45serviceDksoL29TGRMeta } from "/Users/goodboyengineering/projects/yt2tweets-frontend/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: accountBESHg8aqL7Meta || {},
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginayTM77Ne30Meta || {},
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/Users/goodboyengineering/projects/yt2tweets-frontend/pages/terms-of-service.vue").then(m => m.default || m)
  }
]