export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"},{"charset":"utf-8"},{"name":"description","content":"Converts YouTube videos into engaging Twitter threads instantly with AI. Create impactful posts, save time, boost engagement, and share insights effortlessly."},{"property":"og:title","content":"Yt2Tweets - Turn Youtube Videos into Twitter Threads with AI"},{"property":"og:description","content":"Converts YouTube videos into engaging Twitter threads instantly with AI. Create impactful posts, save time, boost engagement, and share insights effortlessly."},{"property":"og:type","content":"website"},{"property":"og:image","content":"https://yt2tweets.worksmarter.lol/og-bg.webp"},{"property":"og:url","content":"https://yt2tweets.worksmarter.lol"},{"name":"twitter:title","content":"Yt2Tweets - Turn Youtube Videos into Twitter Threads with AI"},{"name":"twitter:description","content":"Converts YouTube videos into engaging Twitter threads instantly with AI. Create impactful posts, save time, boost engagement, and share insights effortlessly."},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"@WorkSmarterLOL"},{"name":"twitter:image\"","content":"https://yt2tweets.worksmarter.lol/og-bg.webp"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/favicon-32x32.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"},{"rel":"manifest","href":"/favicon/site.webmanifest"}],"style":[],"script":[],"noscript":[],"title":"Yt2Tweets - Turn Youtube Videos into Twitter Threads with AI","charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false